import React, { ReactNode } from 'react';
import * as Ink from '@carta/ink';

type Props = {
  children: ReactNode;
  accessory?: ReactNode;
};

const Content = (props: Props) => {
  const hasAccessory = typeof props.accessory !== 'undefined';
  return (
    <Ink.Row>
      <Ink.Column
        mobile="12-of-12"
        tabletLandscape={hasAccessory ? '8-of-12' : '12-of-12'}
        desktop={hasAccessory ? '8-of-12' : '12-of-12'}
        desktopLarge={hasAccessory ? '7-of-12' : '12-of-12'}
      >
        {props.children}
      </Ink.Column>
      <Ink.Column
        mobile="hidden"
        tabletLandscape={hasAccessory ? '4-of-12' : 'hidden'}
        desktopLarge={hasAccessory ? '3-of-12' : 'hidden'}
      >
        {props.accessory && props.accessory}
      </Ink.Column>
    </Ink.Row>
  );
};

export default Content;
