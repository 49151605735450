import React, { useContext } from 'react';
import * as Ink from '@carta/ink';

import Header from '../components/Header/Header';
import Content from '../components/Content/Content';
import Layout from '../components/Layout';
import DocsContext from '../contexts/DocsContext';

const Preferences = () => {
  const {
    defaultShowsCode,
    setDefaultShowsCode,
    defaultComponentTab,
    setDefaultComponentTab,
    defaultPropsSort,
    setDefaultPropsSort,
    userRole,
    setUserRole,
    editorStyle,
    setEditorStyle,
    userName,
    setUserName,
  } = useContext(DocsContext);

  return (
    <Layout pageTitle="Preferences">
      <Header title="Preferences" />
      <Content>
        <Ink.Form id="preferences">
          <Ink.NewFormSection>
            <Ink.NewFormSection.Title>Code editor style</Ink.NewFormSection.Title>
            <Ink.NewFormSection.Content>
              <Ink.Field
                help="This setting only impacts the code editor. Site-wide dark mode is not yet available"
                isStacked
              >
                <Ink.NewRadio
                  id="light"
                  name="editor-style"
                  label="Light code editor"
                  value="light"
                  width="half"
                  checked={editorStyle === 'light'}
                  inline
                  onChange={e => setEditorStyle(e.target.value)}
                />
                <Ink.NewRadio
                  id="dark"
                  name="editor-style"
                  label="Dark code editor"
                  value="dark"
                  width="half"
                  checked={editorStyle === 'dark'}
                  inline
                  onChange={e => setEditorStyle(e.target.value)}
                />
              </Ink.Field>
            </Ink.NewFormSection.Content>
          </Ink.NewFormSection>
          <Ink.NewFormSection>
            <Ink.NewFormSection.Title>Experience</Ink.NewFormSection.Title>
            <Ink.NewFormSection.Content>
              <Ink.Field label="Show example code by default" isStacked>
                <Ink.NewRadio
                  id="yes"
                  name="default-shows-code"
                  label="Yes"
                  value="yes"
                  width="quarter"
                  checked={defaultShowsCode}
                  inline
                  onChange={e => setDefaultShowsCode(e.target.value === 'yes')}
                />
                <Ink.NewRadio
                  id="no"
                  name="default-shows-code"
                  label="No"
                  value="no"
                  width="quarter"
                  checked={!defaultShowsCode}
                  inline
                  onChange={e => setDefaultShowsCode(e.target.value === 'yes')}
                />
              </Ink.Field>
              <Ink.Field label="Default component page tab" isStacked>
                <Ink.NewSelect
                  width="half"
                  value={defaultComponentTab}
                  onChange={e => {
                    setDefaultComponentTab(e.target.value);
                  }}
                >
                  <Ink.NewSelect.Option value="examples">Examples</Ink.NewSelect.Option>
                  <Ink.NewSelect.Option value="props">Props</Ink.NewSelect.Option>
                  <Ink.NewSelect.Option value="usage">Usage</Ink.NewSelect.Option>
                </Ink.NewSelect>
              </Ink.Field>
              <Ink.Field label="Default component props sort" isStacked>
                <Ink.NewSelect
                  width="half"
                  value={defaultPropsSort}
                  onChange={e => {
                    setDefaultPropsSort(e.target.value);
                  }}
                >
                  <Ink.NewSelect.Option value="significance">Significance</Ink.NewSelect.Option>
                  <Ink.NewSelect.Option value="category">Category</Ink.NewSelect.Option>
                  <Ink.NewSelect.Option value="alphabetical">Alphabetical</Ink.NewSelect.Option>
                </Ink.NewSelect>
              </Ink.Field>
            </Ink.NewFormSection.Content>
          </Ink.NewFormSection>
          <Ink.NewFormSection>
            <Ink.NewFormSection.Title>Profile</Ink.NewFormSection.Title>
            <Ink.NewFormSection.Subtitle>Help us improve ink documentation for you</Ink.NewFormSection.Subtitle>
            <Ink.NewFormSection.Content>
              <Ink.Field label="Name" isStacked>
                <Ink.NewInput
                  id="user-name"
                  value={userName}
                  onChange={e => {
                    setUserName(e.target.value);
                  }}
                />
              </Ink.Field>
              <Ink.Field label="Role" isStacked>
                <Ink.NewSelect
                  value={userRole}
                  onChange={e => {
                    setUserRole(e.target.value);
                  }}
                >
                  <Ink.NewSelect.Option value="">—</Ink.NewSelect.Option>
                  <Ink.NewSelect.Option value="engineer">Engineer</Ink.NewSelect.Option>
                  <Ink.NewSelect.Option value="product designer">Product Designer</Ink.NewSelect.Option>
                  <Ink.NewSelect.Option value="design technologist">Design Technologist</Ink.NewSelect.Option>
                  <Ink.NewSelect.Option value="product manager">Product Manager</Ink.NewSelect.Option>
                </Ink.NewSelect>
              </Ink.Field>
            </Ink.NewFormSection.Content>
          </Ink.NewFormSection>
        </Ink.Form>
      </Content>
    </Layout>
  );
};

export default Preferences;
